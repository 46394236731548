import React from "react"
import "./project.css"

const Project = (props) => {
    return <div>
        <div className="container">
            <div className="imageWrapper">
                <img src={props.src} />
            </div>
            <div className="descriptionWrapper">
                <h3>
                    {props.title}
                </h3>
                <h4 className="authors">
                    {props.authors}
                </h4>
                <p>
                    {props.note ? <em>{props.note}<br/></em> : ""}
                    Summary: {props.description}
                </p>
                <p>
                    <a href={props.paperUrl} target="_blank">{props.paperUrl ? "Paper" : ""}</a>
                    {props.posterUrl ? ", " : ""}
                    <a href={props.posterUrl} target="_blank">{props.posterUrl ? "Poster" : ""}</a>
                </p>
            </div>
        </div>
    </div>
}

export default Project